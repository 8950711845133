import Vue from 'vue';
import Component from 'vue-class-component';

import template from './footer.html';

@Component({
    template
})
export default class Footer extends Vue
{
}