import { Component, Watch, Vue } from 'vue-property-decorator';

import template from './navigation-bar.html';
import { INavigableItem } from './interfaces/INavigableItem';

/** The navigable items */
const NAVIGABLE_ITEMS: INavigableItem[] = [
    {
        title: "Home",
        path: "/"
    },
    {
        title: "Request Consultation",
        path: "/request-consultation"
    },
    {
        title: "Contact",
        path: "/contact"
    }
]

@Component({
    template
})
export default class NavigationBar extends Vue
{
    // Variables
    // =======================================

    /** Whether or not the dropdown is open */
    private open: boolean = false;

    /** The navigable items */
    private navigableItems: INavigableItem[] = NAVIGABLE_ITEMS;

    /** The currently active path */
    private currentPath: string = "";

    // Watchers
    // =======================================
    
    /**
     * Watcher:
     * Called when the $route property is changed.
     * Store the current active path
     */
    @Watch("$route", { immediate: true })
    private onRouteChange(): void
    {
        this.open = false;
        this.currentPath = this.$router.currentRoute.path;
    }
}
